import React, { Fragment } from "react";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { routes } from "routes";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { formatDurationToString } from "helpers/formatDurationToString";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { commonConstants } from "lynxConstants";
import { BatchDistributionEventModel } from "api/models/events/eventsApi";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { EventType } from "models/thorEvents/eventModels";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { LynxRecommendationIcon } from "components/LynxRecommendationIcon";
import { getAssessmentDecisionText, getAssessmentRecommendationText } from "helpers/getRecommendationText";

interface EventCardProps {
    currentEventId: string;
    event: BatchDistributionEventModel;
    isRelevantEventsHighlighted: boolean;
}

export const EventCard = ({ currentEventId, event, isRelevantEventsHighlighted }: EventCardProps) => {
    const eventTableHeaders = ["Temperature Range", "Excursion"];

    const classes = batchDistributionDiagramPageStyles();

    const isAssessmentNotEvaluatedYet = event.decision == null;

    const groupedMessages = isAssessmentNotEvaluatedYet
        ? getAssessmentRecommendationText(event.recommendationMessages)
        : [];

    const currentEventOnDiagram = currentEventId === event.id;

    return (
        <div
            key={event.id}
            className={clsx(classes.eventsContainer, {
                [classes.eventAssessmentNotEvaluated]: isAssessmentNotEvaluatedYet,
                [classes.greyedOutCard]: isRelevantEventsHighlighted && !event.isRelevant,
            })}
        >
            {currentEventOnDiagram && <div className={classes.selectedEventTriangle} />}
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid container>
                        <Grid>
                            {isAssessmentNotEvaluatedYet && (
                                <LynxIcon name="triangleWarning" className={classes.alertTriangle} />
                            )}
                            <LynxRedirectLink
                                className={clsx(classes.eventLink, {
                                    [classes.notEvaluatedEventLink]: isAssessmentNotEvaluatedYet,
                                })}
                                color={isAssessmentNotEvaluatedYet ? "critical600" : "blue500"}
                                variant="body-sbold-s"
                                typographyComponent={"span"}
                                target="_blank"
                                to={routes.eventDetails.to(event.id)}
                            >
                                Event {event.displayId}
                            </LynxRedirectLink>
                            <Span variant="body-xs" className={classes.eventType}>
                                {EventType[event.type]}
                            </Span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <LynxTypography variant="body-xs" color="neutral400" className={classes.eventCreatedDate}>
                        {dateToFormat(event.createdAt, commonConstants.shortDateFormat, true)}
                    </LynxTypography>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        {eventTableHeaders.map((header) => (
                            <TableCell key={header} className={classes.eventTableCell}>
                                <LynxTypography variant="body-xs" color="neutral400">
                                    {header}
                                </LynxTypography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {event.impacts
                        .sort((a, b) => a.position - b.position)
                        .map((range) => (
                            <TableRow key={range.id}>
                                <TableCell className={classes.eventTableCell}>
                                    <LynxTypography variant={range.excursionDuration > 0 ? "body-sbold-s" : "body-xs"}>
                                        {formatTemperatureRange(
                                            range.rangeDisplayLowerLimit,
                                            range.rangeDisplayUpperLimit,
                                            range.rangeDisplayLowerLimitOperator,
                                            range.rangeDisplayUpperLimitOperator
                                        )}
                                    </LynxTypography>
                                </TableCell>
                                <TableCell className={classes.eventTableCell}>
                                    <LynxTypography variant={range.excursionDuration > 0 ? "body-sbold-s" : "body-xs"}>
                                        {formatDurationToString(range.excursionDuration)}
                                    </LynxTypography>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {currentEventOnDiagram && (
                <div className={classes.assessment}>
                    <LynxRecommendationIcon recomendation={event.decision ?? event.recommendedDecision} />
                    <div>
                        <LynxTypography variant="body-sbold-s">
                            {getAssessmentDecisionText(event.decision ?? event.recommendedDecision)}
                        </LynxTypography>
                        {groupedMessages.map((message, i) => [
                            <Fragment key={`${i}-break`}>{i > 0 && <br />}</Fragment>,
                            <LynxTypography key={i} variant="body-s" color="neutral600">
                                {message}
                            </LynxTypography>,
                        ])}
                    </div>
                </div>
            )}
        </div>
    );
};
