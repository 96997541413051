import React from "react";
import { CopyrightProps } from "./CopyrightProps";
import { copyrightStyles } from "./CopyrightStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { routes } from "routes";

export function Copyright({ className }: CopyrightProps) {
    const classes = copyrightStyles();

    return (
        <div className={className}>
            <LynxTypography variant="body-s" className={classes.firstLine} color="neutral600">
                By Continuing You Agree To Our&nbsp;
                <LynxRedirectLink
                    variant="body-medium"
                    to={routes.termsAndConditions}
                    target="_blank"
                    typographyComponent="span"
                >
                    Terms Of Service
                </LynxRedirectLink>
            </LynxTypography>
            <LynxTypography variant="body-xs" color="neutral400">
                &copy;{new Date().getFullYear()} All Rights Reserved. Carrier&reg;
            </LynxTypography>
        </div>
    );
}
