import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";
import { LynxDateTimePickerForm } from "components/ReusableForms/LynxDateTimePickerForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSearchForm from "components/ReusableForms/LynxSearchForm";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormStyles } from "../StabilityFormStyles";

export const StabilityFormFieldsArea = observer(() => {
    const { stabilityFormId } = useParams();
    const { stabilityFormStore, userStore, identityStore } = useStore();
    const classes = stabilityFormStyles();

    const getCustomerUsers = (value: string) => {
        userStore.loadCustomerUsers({
            pageNumber: 1,
            pageSize: 5,
            customerId: identityStore.currentCustomer.id,
            searchValue: value,
            searchParameter: ["FullName"],
        });
    };

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const navigate = useNavigate();
    const closeNavigate = () =>
        !stabilityFormId
            ? navigate(routes.stabilityForms)
            : navigate(routes.stabilityFormDetails.to(stabilityFormStore.formDetails.id));

    return (
        <SectionCard>
            <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
                <Grid container direction="row" className={withSticky(classes.header)}>
                    <Grid item xs={8}>
                        <LynxTypography variant="h1" className={withSticky(classes.headerText)}>
                            {!stabilityFormId && "New"} {stabilityFormStore.formDetails.product.name} Stability Form
                        </LynxTypography>
                    </Grid>
                    <Grid item container xs={4} justifyContent="flex-end">
                        <Grid item>
                            {/* First submit button is fix to prevent submitting form by pressing "Enter" */}
                            <button
                                form="stabilityForm"
                                type="submit"
                                disabled
                                style={{ display: "none" }}
                                aria-hidden="true"
                            ></button>
                        </Grid>
                        <Grid item>
                            <LynxButton size="medium" variant="tertiary" onClick={() => closeNavigate()}>
                                Cancel
                            </LynxButton>
                            <LynxButton form="stabilityForm" type="submit" size="medium">
                                Save
                            </LynxButton>
                        </Grid>
                    </Grid>
                </Grid>
            </HeaderPortal>
            <Grid container spacing={3} className={clsx(classes.documentInfoSection)}>
                <Grid item xs={3}>
                    <LynxInputForm multiline name="number" label="Document No" placeholder="Enter Document No." />
                </Grid>
                <Grid item xs={3}>
                    <LynxInputForm multiline name="version" label="Version" placeholder="Enter Version Number" />
                </Grid>
                <Grid item xs={3}>
                    <LynxDateTimePickerForm
                        timezone={commonConstants.UTCTimezone}
                        variant="date"
                        name="effectiveDate"
                        label={<LabelWithRequiredSymbol label="Effective Date" />}
                        placeholder="Choose a date"
                        format={commonConstants.shortDateFormat}
                        isDateOnly
                        showToday={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* TODO: replace with wrapped LynxSelectWithSearch */}
                    <LynxSearchForm
                        renderOptionsWithAvatars
                        name="ownerEmail"
                        placeholder="Enter Form Owner"
                        label="Form Owner"
                        showCrossIcon={false}
                        options={userStore.customerUsers.map((x) => ({
                            id: x.id,
                            displayName: `${x.firstName} ${x.lastName}`,
                        }))}
                        search={getCustomerUsers}
                        initialOption={{
                            id: stabilityFormStore.formDetails.ownerEmail,
                            displayName: `${stabilityFormStore.formDetails.ownerFirstName} ${stabilityFormStore.formDetails.ownerLastName}`,
                        }}
                        emptyOption={{ id: "", displayName: "Unassigned" }}
                        loading={userStore.progressFlags.loadingUsersForDropdown}
                        renderInput={() => {
                            return <div></div>;
                        }}
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
});
