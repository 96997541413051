import { useEffect } from "react";
import { Outlet } from "react-router";
import { useStore } from "store/StoreConfigs";

const UserSectionWrapper = () => {
    const { userStore } = useStore();

    const clearUsersPagination = () => {
        userStore.clearPagination();
        userStore.setSearchValue("");
    };

    useEffect(() => {
        return () => {
            clearUsersPagination();
        };
    }, []);

    return <Outlet />;
};

export default UserSectionWrapper;
