import { CustomerLevelRequest } from "../sharedModels/CustomerLevelRequest";

export enum ReportType {
    EventRootCause = 0,
    EventTimeToClose = 1,
}

export interface GetReportCsvRequest extends CustomerLevelRequest {
    type: ReportType;
    currentTimezone: string;
}
