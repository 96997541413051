import { ReactNode } from "react";
import { FullScreenLoadingIndicator } from "./FullScreenLoadingIndicator";

interface FullScreenLoadingIndicatorWrapperProps {
    showLoading: boolean;
    children: ReactNode;
}

export const FullScreenLoadingIndicatorWrapper = ({
    showLoading,
    children,
}: FullScreenLoadingIndicatorWrapperProps) => {
    return showLoading ? <FullScreenLoadingIndicator /> : <>{children}</>;
};
