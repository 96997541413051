import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { observer } from "mobx-react";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { formatDurationToString } from "helpers/formatDurationToString";
import { AssessmentDecision, Flow } from "models/thorEvents/eventModels";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { useStore } from "store/StoreConfigs";
import { getPermissionKey } from "helpers/permissionHelpers";
import { actions } from "models/userManagement/actions";
import clsx from "clsx";
import { BatchHistoryProps } from "./BatchHistoryProps";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { routes } from "routes";
import { productDecisionsText, commonConstants } from "lynxConstants";
import { evaluationCompleteText } from "lynxConstants/assessmentsText";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { LynxTab } from "components/LynxComponents/LynxTab/LynxTab";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { PaginationArea } from "models/shared/Page";
import { LynxInfoBadge } from "components/LynxComponents/LynxInfoBadge";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxIcon } from "icons/LynxIcon";

export const BatchHistory = observer((props: BatchHistoryProps) => {
    const { permissionsStore, identityStore, thorEventViewStore } = useStore();
    const { batchHistory, flows, assessmentId, assessmentIsClosed, assessmentCompletedDate } = props;

    const classes = assessmentDetailsStyles();
    const tableHeaders = [
        "Temp. Range of Excursion",
        "Event Excursion Time",
        "Batch Remaining Stability Budget",
        "Can Product be used?",
        "With Customer?",
        "Batch Hours Reduced?",
        "Decision Made By",
    ];

    const parseDecisionEnum = (decision: AssessmentDecision | null) => {
        switch (decision) {
            case AssessmentDecision.NoImpactOnProductQuality:
                return (
                    <LynxTypography variant="body-medium" color="success500">
                        Yes
                    </LynxTypography>
                );
            case AssessmentDecision.ExistingImpactOnProductQuality:
                return (
                    <LynxTypography variant="body-medium" color="critical500">
                        No
                    </LynxTypography>
                );
            default:
                return (
                    <LynxTypography variant="body-medium" color="neutral300">
                        {productDecisionsText.noDecision}
                    </LynxTypography>
                );
        }
    };

    const willHoursBeReduced = (isWithFirstEconomicCustomer: boolean | null, decision: AssessmentDecision | null) => {
        if (isWithFirstEconomicCustomer || decision === AssessmentDecision.ExistingImpactOnProductQuality) {
            return <LynxTypography>No</LynxTypography>;
        } else if (decision === null || decision === undefined) {
            return <LynxTypography color="neutral300">{productDecisionsText.noDecision}</LynxTypography>;
        } else if (decision === AssessmentDecision.AdditionalReviewRequired) {
            throw new Error(`Final assessment decision should not be ${productDecisionsText.additionalReviewRequired}`);
        } else {
            return <LynxTypography>Yes</LynxTypography>;
        }
    };
    const viewEventDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.events.viewDetails,
        identityStore.currentCustomer.id
    );

    const getFlowRegionAndSteps = (flow: Flow | undefined) => {
        if (flow) {
            return `${flow.region ?? "All Regions and Countries"}: ${
                flow.name ?? flow.steps ?? commonConstants.emptyValue
            }`;
        } else {
            return commonConstants.emptyValue;
        }
    };

    const batchHistoryIsNotEmpty = thorEventViewStore.batchHistoryAllEventsCount > 0;

    const closedAt = convertDateToSelectedTimezone(
        assessmentCompletedDate,
        thorEventViewStore.eventDetails.timezone
    ).format(commonConstants.fullDateTimeFormat);
    const closedAssessmentText = `${evaluationCompleteText} ${closedAt}`;

    return (
        <AssessmentDetailsSection
            title={"Batch History"}
            titleRightSide={
                <div className={classes.batchHistoryNavigationContainer}>
                    {batchHistoryIsNotEmpty && (
                        <div className={classes.batchHistoryTabsContainer}>
                            <LynxTab
                                tabPosition="leftTab"
                                tabText={`All Events (${thorEventViewStore.batchHistoryAllEventsCount})`}
                                isSelected={!thorEventViewStore.batchHistoryRelevantEventsTabSelected}
                                buttonProps={{
                                    onClick: () => thorEventViewStore.setShowRelevantEventsOnly(false),
                                }}
                            />
                            <LynxTab
                                tabPosition="rightTab"
                                tabText={`Relevant Events (${thorEventViewStore.batchHistoryRelevantEventsCount})`}
                                isSelected={thorEventViewStore.batchHistoryRelevantEventsTabSelected}
                                buttonProps={{
                                    onClick: () => thorEventViewStore.setShowRelevantEventsOnly(true),
                                }}
                            />
                        </div>
                    )}
                    <LynxButton
                        className={classes.batchDistributionDiagramButton}
                        variant="secondary"
                        onClick={() =>
                            window.open(
                                routes.batchDistributionDiagram.to(thorEventViewStore.eventDetails.id, assessmentId),
                                "_blank"
                            )
                        }
                    >
                        <LynxIcon name="structure" />
                    </LynxButton>
                </div>
            }
        >
            {assessmentIsClosed && batchHistoryIsNotEmpty && (
                <div className={classes.closedBatchInfoBadge}>
                    <LynxInfoBadge text={closedAssessmentText} />
                </div>
            )}

            {thorEventViewStore.progressFlags.loadingBatchHistory ? (
                <LoadingIndicator className={classes.batchHistoryloadingIndicator} />
            ) : batchHistoryIsNotEmpty &&
              !(
                  thorEventViewStore.batchHistoryRelevantEventsTabSelected &&
                  thorEventViewStore.batchHistoryRelevantEventsCount === 0
              ) ? (
                <>
                    {batchHistory.map((batchHistoryEntry) => (
                        <div key={batchHistoryEntry.eventId} className={classes.batchHistoryCardContainer}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <LynxTypography variant="body-l">
                                        Event &nbsp;
                                        <LynxRedirectLink
                                            typographyComponent="span"
                                            target="_blank"
                                            to={routes.eventDetails.to(batchHistoryEntry.eventId)}
                                            disabled={!permissionsStore.hasPermission(viewEventDetailsPermissionKey)}
                                            loading={permissionsStore.permissionLoading(viewEventDetailsPermissionKey)}
                                        >
                                            #{batchHistoryEntry.eventDisplayId}
                                        </LynxRedirectLink>
                                    </LynxTypography>
                                </Grid>
                                <Grid item>
                                    <Span
                                        variant="body-s"
                                        color="neutral400"
                                        className={classes.batchHistoryEventCreationDateMargin}
                                    >
                                        Event Creation Date: &nbsp;
                                        <Span variant="body" color="neutral600">
                                            {convertDateToSelectedTimezone(
                                                batchHistoryEntry.eventCreationDate,
                                                thorEventViewStore.eventDetails.timezone
                                            ).format(commonConstants.fullDateTimeFormat)}
                                        </Span>
                                    </Span>
                                    <Span variant="body-s" color="neutral400">
                                        Flow: &nbsp;
                                        <Span variant="body" color="neutral600">
                                            {getFlowRegionAndSteps(
                                                flows.find((flow) => flow.id === batchHistoryEntry.flowId)
                                            )}
                                        </Span>
                                    </Span>
                                </Grid>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.verticalAlignBottom}>
                                        {tableHeaders.map((header) => (
                                            <TableCell key={header} className={classes.batchHistoryHeaderCell}>
                                                <LynxTypography variant="body-s" color="neutral400">
                                                    {header}
                                                </LynxTypography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        className={clsx(classes.historyTableRow, classes.verticalAlignTop)}
                                        key={batchHistoryEntry.eventId}
                                    >
                                        <TableCell
                                            className={clsx(classes.batchHistoryTempCell, classes.batchHistoryDataCell)}
                                        >
                                            <LynxTypography>
                                                {batchHistoryEntry.impacts
                                                    .slice()
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((x) => (
                                                        <>
                                                            {formatTemperatureRange(
                                                                x.rangeDisplayLowerLimit,
                                                                x.rangeDisplayUpperLimit,
                                                                x.rangeDisplayLowerLimitOperator,
                                                                x.rangeDisplayUpperLimitOperator
                                                            )}
                                                            <br />
                                                        </>
                                                    ))}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography>
                                                {batchHistoryEntry.impacts
                                                    .slice()
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((x) => (
                                                        <>
                                                            {formatDurationToString(x.excursionDuration)}
                                                            <br />
                                                        </>
                                                    ))}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography>
                                                {batchHistoryEntry.impacts
                                                    .slice()
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((x) => (
                                                        <>
                                                            {formatDurationToString(x.batchRsbAfter)}
                                                            <br />
                                                        </>
                                                    ))}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography>
                                                {parseDecisionEnum(batchHistoryEntry.decision)}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography
                                                color={
                                                    batchHistoryEntry.isWithFirstEconomicCustomer === null
                                                        ? "neutral300"
                                                        : "neutral600"
                                                }
                                            >
                                                {batchHistoryEntry.isWithFirstEconomicCustomer === null
                                                    ? productDecisionsText.noDecision
                                                    : batchHistoryEntry.isWithFirstEconomicCustomer
                                                    ? "Yes"
                                                    : "No"}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            {willHoursBeReduced(
                                                batchHistoryEntry.isWithFirstEconomicCustomer,
                                                batchHistoryEntry.decision
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            {batchHistoryEntry.qaUserId ? (
                                                <Grid container wrap="nowrap" alignItems="center">
                                                    <Grid item>
                                                        <LynxAvatarWithTooltip
                                                            email={batchHistoryEntry.qaUserId}
                                                            firstName={batchHistoryEntry.qaFirstName}
                                                            lastName={batchHistoryEntry.qaLastName}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <LynxTypography>{`${batchHistoryEntry.qaFirstName} ${batchHistoryEntry.qaLastName} `}</LynxTypography>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <LynxTypography color="neutral300">
                                                    {productDecisionsText.noDecision}
                                                </LynxTypography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    ))}

                    <ThorPagination
                        page={thorEventViewStore.batchHistoryCurrentPage}
                        pages={thorEventViewStore.batchHistoryPages}
                        onPageChange={thorEventViewStore.moveToBatchHistoryPage}
                        isLastPage={thorEventViewStore.isLastBatchHistoryPage}
                        setPage={thorEventViewStore.setBatchHistoryPage}
                        area={PaginationArea.BatchHistory}
                        setPageSize={thorEventViewStore.setBatchHistoryPageSize}
                    />
                </>
            ) : (
                <LynxTypography color="neutral300">No previous events</LynxTypography>
            )}
        </AssessmentDetailsSection>
    );
});
