import { firstLevelHeaderPortalId, secondLevelHeaderPortalId } from "layout/LynxHeader";
import { ScrollWatcher } from "./ScrollWatcher";
import { createPortal } from "react-dom";
import { ReactNode } from "react";

export interface HeaderPortalRenderingProps {
    children: ReactNode;
    stickyEnabled: boolean;
    callback: (isOutsideViewport: boolean) => void;
    firstLevel: boolean;
}

export const HeaderPortal = (props: HeaderPortalRenderingProps) => {
    const portalNode = props.firstLevel
        ? document.getElementById(firstLevelHeaderPortalId)
        : document.getElementById(secondLevelHeaderPortalId);

    return (
        <>
            <ScrollWatcher callback={props.callback} />
            {props.stickyEnabled && portalNode ? createPortal(props.children, portalNode!) : props.children}
        </>
    );
};

HeaderPortal.defaultProps = {
    firstLevel: true,
};
