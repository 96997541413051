export interface CommentFormModal {
    comment: string;
}

export interface CommentBaseModel {
    comment: string | null;
}

export enum CommentType {
    General = "General",
    Assessment = "Assessment",
    TemperatureGraph = "TemperatureGraph",
    EditEventBody = "EditEventBody",
    CreateEvent = "CreateEvent",
    EditEvent = "EditEvent",
    AssignEvent = "AssignEvent",
}

export interface CommentModel extends CommentBaseModel {
    id: string;
    userEmail: string;
    userFirstName?: string;
    userLastName?: string;
    createdAt: Date;
    createdSection: CommentType;
    payload?: CommentPayload;
}

export interface CommentPayload {
    id: string;
    firstName: string;
    lastName: string;
    notShowEmptyStateIfNoContent?: boolean;
}

export interface LinkItem {
    text: string;
    url: string;
}

export interface SidebarPermissions {
    excursionEvaluation: boolean;
    stabilityDataManagement: boolean;
    batchLookup: boolean;
    reporting: boolean;
    userManagement: boolean;
}

export interface JustificationFormModal {
    justification: string;
}
