import { NodeProps } from "@xyflow/react";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxDeliveryNode } from "./BatchDistributionDiagramTypes";
import { DummyNodeHandle } from "./DummyNodeHandle";
import { EventCard } from "./EventCard";
import clsx from "clsx";
import { diagramFormatDate } from "./batchDistributionDiagramHelpers";

export const DeliveryNode = (props: NodeProps<LynxDeliveryNode>) => {
    const classes = batchDistributionDiagramPageStyles();

    const areAllEventsNotRelevant = props.data.events.length > 0 && props.data.events.every((x) => !x.isRelevant);

    return (
        <>
            <div
                className={clsx(classes.deliveryNode, {
                    [classes.greyedOutCard]: props.data.isRelevantEventsHighlighted && areAllEventsNotRelevant,
                    [classes.selectedEventNode]: props.data.events.some((x) => x.id === props.data.currentEventId),
                })}
            >
                <LynxTypography variant="body-xs">Delivery {props.data.deliveryNumber}</LynxTypography>
                <LynxTypography variant="body-xs" color="neutral400">
                    {diagramFormatDate(props.data.shippingDate)} — {diagramFormatDate(props.data.deliveryDate)}
                </LynxTypography>

                {props.data.events.length !== 0 &&
                    props.data.events.map((event) => (
                        <EventCard
                            currentEventId={props.data.currentEventId}
                            event={event}
                            key={event.id}
                            isRelevantEventsHighlighted={props.data.isRelevantEventsHighlighted}
                        />
                    ))}
            </div>

            <DummyNodeHandle type="source" />
            <DummyNodeHandle type="target" />
        </>
    );
};
