import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { services } from "api/serviceConfig";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { StabilityFormAuditPage } from "components/StabilityForm/StabilityFormAudit/StabilityFormAuditPage";
import { StabilityFormDetails } from "components/StabilityForm/StabilityFormDetails/StabilityFormDetails";
import StabilityFormSectionWrapper from "components/StabilityForm/StabilityFormSectionWrapper";
import { StabilityFormSummary } from "components/StabilityForm/Summary/StabilityFormSummary";
import { TermsAndConditionsComponent } from "components/TermsAndConditions/TermsAndConditions";
import { EventAuditPage } from "components/ThorEvents/EventAuditPage/EventAuditPage";
import { EventCreation } from "components/ThorEvents/EventCreation/EventCreation";
import { EventDetails } from "components/ThorEvents/EventDetails/EventDetails";
import EventSectionWrapper from "components/ThorEvents/EventSectionWrapper";
import { localStorageService } from "helpers/localStorageService";
import { createPermissionRequest, getPermissionKey } from "helpers/permissionHelpers";
import { LynxHeader } from "layout/LynxHeader";
import { LynxSidebar } from "layout/LynxSidebar";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { LocalStorageBooleanKey, LocalStorageStringKey } from "models/shared/LocalStorageKeyEnums";
import { actions } from "models/userManagement/actions";
import {
    AvailableCustomer,
    MarvelPermissionStatus,
    PermissionRequest,
    ResourceType,
    UserStatus,
    UserType,
} from "models/userManagement/userManagementModels";
import { EventsView } from "pages/EventsView/EventsView";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { StabilityFormsView } from "pages/StabilityFormsView/StabilityFormsView";
import { Reporting } from "pages/Reporting/Reporting";
import UserView from "pages/UserView/UserView";
import { useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { LoginLayout } from "./LoginLayout";
import { mainLayoutStyles } from "./MainLayoutStyles";
import { BlankPageForRedirectFromApp } from "./BlankPageForRedirectFromApp";
import { BatchDistributionDiagramPage } from "pages/BatchDistributionDiagramPage/BatchDistributionDiagramPage";
import { LynxScrollToTopButton } from "components/ReusableComponents/LynxScrollToTopButton/LynxScrollToTopButton";

const getCustomerLevelPermissionsRequests = (userId: string, customerId: string): PermissionRequest[] => {
    let result: PermissionRequest[];

    if (customerId === commonConstants.system) {
        result = [
            createPermissionRequest(userId, actions.system.customers.view, ResourceType.None),
            createPermissionRequest(userId, actions.system.customers.manage, ResourceType.None),
            createPermissionRequest(userId, actions.system.groups.view, ResourceType.None),
            createPermissionRequest(userId, actions.system.groups.manage, ResourceType.None),
            createPermissionRequest(userId, actions.system.users.view, ResourceType.None),
            createPermissionRequest(userId, actions.system.users.manage, ResourceType.None),
        ];
    } else {
        result = [
            // Events
            createPermissionRequest(userId, actions.customer.tor.events.view, ResourceType.Customer, customerId),
            createPermissionRequest(userId, actions.customer.tor.events.viewDetails, ResourceType.Customer, customerId),
            createPermissionRequest(userId, actions.customer.tor.events.create, ResourceType.Customer, customerId),
            createPermissionRequest(userId, actions.customer.tor.events.assign, ResourceType.Customer, customerId),
            createPermissionRequest(
                userId,
                `${actions.customer.tor.events.editManualEvent}::customer`,
                ResourceType.Customer,
                customerId
            ),

            // Stability forms
            createPermissionRequest(
                userId,
                actions.customer.tor.stabilityForms.view,
                ResourceType.Customer,
                customerId
            ),

            createPermissionRequest(
                userId,
                actions.customer.tor.stabilityForms.viewDetails,
                ResourceType.Customer,
                customerId
            ),
            createPermissionRequest(
                userId,
                actions.customer.tor.stabilityForms.modify,
                ResourceType.Customer,
                customerId
            ),

            // Reporting
            createPermissionRequest(userId, actions.customer.tor.reporting.view, ResourceType.Customer, customerId),

            // User management
            createPermissionRequest(userId, actions.customer.users.view, ResourceType.Customer, customerId),
            createPermissionRequest(userId, actions.customer.users.manage, ResourceType.Customer, customerId),
            createPermissionRequest(userId, actions.customer.groups.view, ResourceType.Customer, customerId),
            createPermissionRequest(userId, actions.customer.groups.manage, ResourceType.Customer, customerId),
        ];
    }

    return result;
};

export const MainLayout = observer(() => {
    const classes = mainLayoutStyles();
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const { commonStore, identityStore, permissionsStore } = useStore();
    const [customersCount, setCustomersCount] = useState(1);
    const prevCustomerRef = useRef<AvailableCustomer>(identityStore.currentCustomer);
    const [hasAccessToAnything, sethasAccessToAnything] = useState(false);

    const initialRequests = async () => {
        await identityStore.loadIdentity();

        if (localStorageService.getBoolean(LocalStorageBooleanKey.UserSignedIn)) {
            try {
                await services.Audit.auditUserSignInSuccess();
            } finally {
                localStorageService.removeBoolean(LocalStorageBooleanKey.UserSignedIn);
            }
        }
    };

    useEffect(() => {
        initialRequests();

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, []);

    const redirectTo = (page: string) => {
        const currentUrl = toRelativeUrl(window.location.href, window.location.origin);

        if (page !== routes.root) {
            identityStore.setStartPageAvailable(page);
        }

        if (currentUrl === routes.root) {
            navigate(page);
            return;
        }

        navigate(currentUrl);
    };

    const evaluatePermissionsAndRedirect = async () => {
        if (
            identityStore.currentUser.id === "" ||
            identityStore.currentCustomer.id === "" ||
            identityStore.currentUser.status === UserStatus.Deactivated
        ) {
            return;
        }

        if (prevCustomerRef.current.id !== "") {
            navigate(routes.root);
        }

        prevCustomerRef.current = identityStore.currentCustomer;

        let permissionRequests = getCustomerLevelPermissionsRequests(
            identityStore.currentUser.id,
            identityStore.currentCustomer.id
        );

        const eventsViewPermissionKey = getPermissionKey(
            actions.customer.tor.events.view,
            identityStore.currentCustomer.id
        );
        const stabilityFormsViewPermissionKey = getPermissionKey(
            actions.customer.tor.stabilityForms.view,
            identityStore.currentCustomer.id
        );

        const reportingPermissionKey = getPermissionKey(
            actions.customer.tor.reporting.view,
            identityStore.currentCustomer.id
        );

        const userManagementPermissionsKeys = identityStore.isSystemSpace
            ? [
                  getPermissionKey(actions.system.customers.view),
                  getPermissionKey(actions.system.users.view),
                  getPermissionKey(actions.system.groups.view),
              ]
            : [
                  getPermissionKey(actions.customer.users.view, identityStore.currentCustomer.id),
                  getPermissionKey(actions.customer.groups.view, identityStore.currentCustomer.id),
              ];

        if (
            identityStore.currentUser.type === UserType.SystemUser &&
            identityStore.currentCustomer.id !== commonConstants.system
        ) {
            permissionRequests = permissionRequests.concat(
                getCustomerLevelPermissionsRequests(identityStore.currentUser.id, commonConstants.system)
            );
        }

        const permissionsResult = await permissionsStore.bulkEvaluate(permissionRequests);

        const hasAccessToEvents = permissionsResult.some(
            (x) => x.key === eventsViewPermissionKey && x.status === MarvelPermissionStatus.Allow
        );

        const hasAccessToStabilityForms = permissionsResult.some(
            (x) => x.key === stabilityFormsViewPermissionKey && x.status === MarvelPermissionStatus.Allow
        );

        const hasAccessToUserManagement = permissionsStore.hasPermission(...userManagementPermissionsKeys);

        const hasAccessToReporting = permissionsStore.hasPermission(reportingPermissionKey);

        switch (true) {
            case hasAccessToEvents:
                sethasAccessToAnything(true);
                redirectTo(routes.events);
                break;
            case hasAccessToStabilityForms:
                sethasAccessToAnything(true);
                redirectTo(routes.stabilityForms);
                break;
            case hasAccessToUserManagement:
                sethasAccessToAnything(true);
                redirectTo(routes.userManagement);
                break;
            case hasAccessToReporting:
                sethasAccessToAnything(true);
                redirectTo(routes.reporting);
                break;
            case customersCount < identityStore.customers.length &&
                identityStore.currentUser.type === UserType.SystemUser:
                // change customer and re-evaluate permissions
                sethasAccessToAnything(false);
                setCustomersCount(customersCount + 1);
                identityStore.setCurrentCustomer(identityStore.customers[customersCount].id);
                break;
            default:
                localStorageService.setString(
                    LocalStorageStringKey.UserLoginError,
                    "You do not have access to this system, contact System Administrator"
                );
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
                break;
        }

        // Necessary check to setCustomersCount 0, for checking permissions across ALL customers
        if (hasAccessToAnything) {
            setCustomersCount(0);
            redirectTo(routes.root);
        }
    };

    useEffect(() => {
        evaluatePermissionsAndRedirect();

        return () => {
            setCustomersCount(0);
        };
    }, [identityStore.currentCustomer]);

    useEffect(() => {
        if (identityStore.currentUser.id && identityStore.currentUser.status === UserStatus.Deactivated) {
            oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
        }
    }, [identityStore.loading, identityStore.currentUser]);

    if (
        (identityStore.currentUser.id === "" || identityStore.currentCustomer.id === "") &&
        !commonStore.showGeneralErrorPage
    ) {
        return <FullScreenLoadingIndicator />;
    }

    if (!identityStore.currentUser.termsApproved && hasAccessToAnything) {
        return <LoginLayout component={<TermsAndConditionsComponent />} isLargeContent={true} />;
    }

    return (
        <div className={classes.root}>
            {commonStore.showSidebarAndHeader && <LynxSidebar />}

            <div className={classes.rightSide}>
                {/* id just for visibility when inspecting HTML, it's not used */}
                <div ref={(node) => commonStore.setScrollWatcherTarget(node)} id="scroll-watcher-target" />

                {commonStore.showSidebarAndHeader && <LynxHeader />}
                <LynxScrollToTopButton />

                <Routes>
                    {/* default (index) path for redirects */}
                    <Route index element={<BlankPageForRedirectFromApp />} />
                    <Route element={<EventSectionWrapper />}>
                        <Route path={routes.events} element={<EventsView />} />
                        <Route path={routes.eventsCreate} element={<EventCreation />} />
                        <Route path={routes.eventDetails.path} element={<EventDetails />} />
                        <Route path={routes.eventsEdit.path} element={<EventCreation />} />
                        <Route path={routes.eventsAudit.path} element={<EventAuditPage />} />
                        <Route path={routes.batchDistributionDiagram.path} element={<BatchDistributionDiagramPage />} />
                    </Route>
                    <Route element={<StabilityFormSectionWrapper />}>
                        <Route path={routes.stabilityForms} element={<StabilityFormsView />} />
                        <Route path={routes.stabilityFormsCreate} element={<StabilityFormDetails />} />
                        <Route path={routes.stabilityFormDetails.path} element={<StabilityFormSummary />} />
                        <Route path={routes.stabilityFormsEdit.path} element={<StabilityFormDetails />} />
                        <Route path={routes.stabilityFormsAudit.path} element={<StabilityFormAuditPage />} />
                    </Route>
                    <Route path={routes.reporting} element={<Reporting />} />
                    <Route path="userManagement/*" element={<UserView />} />
                    <Route path={routes.pageNotFound} element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate to={routes.pageNotFound} />} />
                </Routes>
            </div>
        </div>
    );
});
