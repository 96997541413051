import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const lynxHeaderStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            position: "sticky",
            top: 0,
            padding: "1rem 1.5rem",
            zIndex: 1,
            backgroundColor: theme.colors.primary.lynxbg,

            "&.sticky": {
                backgroundColor: theme.colors.neutrals.white,
                borderBottom: `1px solid ${theme.colors.primary.blue100}`,
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.08)",
                padding: "1rem 1.5rem 0.5rem 1.5rem",
            },
        },

        upperHeader: {
            display: "flex",
            alignItems: "center",
            transition: theme.transitions.create("sticky", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),

            "&.sticky": {
                position: "sticky",
            },
        },

        rightSide: {
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
        },

        customerDropdown: {
            fontSize: "0.75rem",
            lineHeight: "1rem",
            marginRight: "20px",
            width: "180px",

            "& > .MuiSelect-root": {
                padding: "6px 50px 6px 12px",
            },
        },

        profileButton: {
            gap: "0.5rem",
            "&:focus-visible": {
                borderRadius: theme.borderRadius,
                outline: theme.outline,
            },

            "&:hover": {
                textDecoration: "underline",
            },
        },

        userNameText: {
            marginLeft: "0.25rem",
            marginRight: "0.5rem",
        },

        profilePopover: {
            maxWidth: "20rem",
            padding: "0.25rem 0",
            border: `1px solid ${theme.colors.primary.blue100}`,
            boxShadow: "0px 0px 10px 0px rgba(51, 51, 51, 0.50)",
            // required to correctly display orange outline
            overflow: "visible",
        },

        popoverFirstSection: {
            display: "flex",
            padding: "1rem",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
            gap: "1rem",
        },

        popoverEmailAndName: {
            display: "flex",
            flexDirection: "column",
        },

        logoutButton: {
            padding: "0.4375rem 1rem 0.5rem 1rem",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "100%",
            justifyContent: "start",
            color: theme.colors.neutrals.neutral500,
            fill: theme.colors.neutrals.neutral500,

            "&:hover": {
                backgroundColor: theme.colors.primary.blue75,
                color: theme.colors.primary.blue500,
                fill: theme.colors.primary.blue500,
            },

            "&:focus-visible": {
                outline: theme.outline,
                borderRadius: theme.borderRadius,
            },
        },
    });
});
