import { NodeProps } from "@xyflow/react";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import {
    LynxGroupedDeliveriesNode,
} from "./BatchDistributionDiagramTypes";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { DummyNodeHandle } from "./DummyNodeHandle";

export const GroupedDeliveriesNode = observer((props: NodeProps<LynxGroupedDeliveriesNode>) => {
    const classes = batchDistributionDiagramPageStyles();
    const { batchDistributionDiagramStore } = useStore();

    const openPopup = () => {
        batchDistributionDiagramStore.openGroupedDeliveriesDetails(
            props.data.originPhysicalId,
            props.data.destinationPhysicalId,
            props.data.deliveryNumbers
        );
    };

    return (
        <>
            <div className={classes.deliveryNode}>
                <LynxTypography variant="body-xs">{props.data.deliveryNumbers.length} Deliveries</LynxTypography>
                <LynxRedirectLink variant="body-xs" to="" onClick={openPopup}>
                    See Details
                </LynxRedirectLink>
            </div>
            <DummyNodeHandle type="source" />
            <DummyNodeHandle type="target" />
        </>
    );
});
