import { observer } from "mobx-react";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { useStore } from "store/StoreConfigs";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { diagramFormatDate } from "./batchDistributionDiagramHelpers";

export const BatchDistributionDiagramPopup = observer(() => {
    const classes = batchDistributionDiagramPageStyles();
    const { batchDistributionDiagramStore } = useStore();
    const data = batchDistributionDiagramStore.groupedDeliveriesDetails;
    const origin = data?.originPhysicalId ? `${data.originName} (${data.originPhysicalId})` : "Unknown";
    const destination = data?.destinationPhysicalId
        ? `${data.destinationName} (${data.destinationPhysicalId})`
        : "Unknown";

    const closePopup = () => {
        batchDistributionDiagramStore.clearGroupedDeliveriesDetails();
    };

    const TableHeaderCell = (props: { title: string }) => {
        return (
            <TableCell className={classes.popupTableCell}>
                <LynxTypography variant="body-xs" color="neutral400">
                    {props.title}
                </LynxTypography>
            </TableCell>
        );
    };

    return (
        <div className={classes.popup}>
            <div className={classes.popupHeader}>
                <div>
                    <LynxTypography variant="h3">Deliveries ({data?.deliveries.length})</LynxTypography>
                    <LynxTypography variant="body-xs">
                        From {origin} to {destination}
                    </LynxTypography>
                </div>

                <LynxIcon name="cross" onClick={closePopup} cursor="pointer" />
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell title="Delivery ID" />
                        <TableHeaderCell title="Shipping & Delivery Dates" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.deliveries.map((delivery) => (
                        <TableRow key={delivery.deliveryNumber}>
                            <TableCell className={classes.popupTableCell} width="50%">
                                <LynxTypography variant="body-xs">Delivery {delivery.deliveryNumber}</LynxTypography>
                            </TableCell>
                            <TableCell className={classes.popupTableCell} width="50%">
                                <LynxTypography variant="body-xs" color="neutral400">
                                    {diagramFormatDate(delivery.shippingDate)} —&nbsp;
                                    {diagramFormatDate(delivery.deliveryDate)}
                                </LynxTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
});
