import { useOktaAuth } from "@okta/okta-react";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import SetApiValidationErrors from "validation/HelperComponents/SetApiValidationsErrors";
import { forgotPaswordValidationSchema } from "validation/ValidationSchemas/forgotPasswordValidationSchema";
import { validationHelpers } from "validation/helper-functions";
import { loginStyles } from "../LoginStyles";

const EnterEmailForResetPassword = observer(() => {
    const { userStore } = useStore();
    const navigate = useNavigate();
    const classes = loginStyles();
    const { oktaAuth, authState } = useOktaAuth();

    const handleResponse = (statusCode: number, errors: ApiValidationError[]) => {
        if (statusCode === 202) {
            userStore.setForgotPasswordNavigationFlag("emailSentPageVisited", true);
            navigate(routes.recoveryEmailed, { replace: true });
        } else if (statusCode === 400) {
            userStore.setForgotPasswordErrors(validationHelpers.generateFormikErrorsFromApi<{ email: string }>(errors));
        }
    };

    const onSubmit = (values: { email: string }) => userStore.forgotPassword(values.email, handleResponse);

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            oktaAuth.revokeAccessToken();
        }
    }, [authState]);

    return (
        <>
            <LynxTypography variant="h2" className={clsx(classes.title, classes.marginTopVeryLarge)}>
                Reset Password
            </LynxTypography>

            <LynxTypography className={classes.marginBottomLarge}>
                Enter your email and we&apos;ll send you a link to reset your password
            </LynxTypography>

            <Formik
                initialValues={{ email: "" }}
                validationSchema={forgotPaswordValidationSchema()}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <SetApiValidationErrors storeErrors={userStore.forgotPasswordErrors} />
                    <LynxInputForm
                        autoComplete="on"
                        name="email"
                        label="Email"
                        placeholder="Enter email"
                        formControlClassName={classes.marginBottomMedium}
                    />
                    <LynxButton
                        size="large"
                        type="submit"
                        className={classes.buttonFullWidth}
                        loading={userStore.progressFlags.forgotPassword}
                        disabled={userStore.progressFlags.forgotPassword}
                    >
                        Send recovery link to email
                    </LynxButton>
                </Form>
            </Formik>
        </>
    );
});

export default EnterEmailForResetPassword;
