export const actions = {
    system: {
        customers: {
            view: "system.customers.view",
            manage: "system.customers.manage",
        },
        users: {
            view: "system.users.view",
            manage: "system.users.manage",
        },
        groups: {
            view: "system.groups.view",
            manage: "system.groups.manage",
        },
    },
    customer: {
        users: {
            view: "customer.users.view",
            manage: "customer.users.manage",
        },
        groups: {
            view: "customer.groups.view",
            manage: "customer.groups.manage",
        },
        tor: {
            events: {
                view: "customer.tor.events.view",
                viewDetails: "customer.tor.events.viewDetails",
                assign: "customer.tor.events.assign",
                editInformation: "customer.tor.events.editInformation",
                editDetails: "customer.tor.events.editDetails",
                conditionTrip: "customer.tor.events.conditionTrip",
                review: "customer.tor.events.review",
                approve: "customer.tor.events.approve",
                create: "customer.tor.events.create",
                editManualEvent: "customer.tor.events.editManualEvent",
            },
            stabilityForms: {
                view: "customer.tor.stabilityForms.view",
                viewDetails: "customer.tor.stabilityForms.viewDetails",
                modify: "customer.tor.stabilityForms.modify",
            },
            reporting: {
                view: "customer.tor.reporting.view",
            },
        },
        risk: "customer.risk",
        lanePlanning: "customer.lanePlanning",
        distributionFlow: "customer.distributionFlow",
    },
};

const flatten = (obj: any): string[] => {
    if (typeof obj === "string") {
        return [obj];
    }

    let result: string[] = [];

    Object.keys(obj).forEach((x) => {
        result = [...result, ...flatten(obj[x])];
    });

    return result;
};

export const actionsOrder = flatten(actions);
