import { makeAutoObservable } from "mobx";
import CommonStore from "./CommonStore";
import { services } from "api/serviceConfig";
import { extractFileNameFromContentDisHeader, forceFileDownload } from "helpers/fileHelpers";
import _ from "lodash";
import { GetReportCsvRequest } from "api/models/reporting/reportingApi";

export class ReportingStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    commonStore: CommonStore;

    reportingProgressFlags: { [key: string]: boolean } = {};

    getReportCsv = async (request: GetReportCsvRequest) => {
        this.reportingProgressFlags[request.type] = true;

        try {
            const response = await services.Reporting.getReportCsv(request);

            if (!_.inRange(response.status, 200, 300)) {
                return;
            }

            const filename = extractFileNameFromContentDisHeader(response);

            forceFileDownload(filename, response.data);
        } finally {
            this.reportingProgressFlags[request.type] = false;
        }
    };
}
