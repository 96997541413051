import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const stabilityFormsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.listPagePadding,
        },

        tabList: {
            backgroundColor: "white",
            paddingLeft: "24px",
            borderBottom: `1px solid ${theme.colors.neutrals.neutral200}`,

            "& .MuiTab-textColorInherit.Mui-selected": {
                color: theme.colors.primary.blue500,
                opacity: 1,
            },
            "& .MuiTabs-indicator": {
                backgroundColor: theme.colors.primary.blue500,
            },
        },
        tab: {
            minWidth: "72px",
        },
        cellBorderBottom: {
            borderBottom: "none",
        },
        headerCell: {
            paddingBottom: "1rem",
        },
        tableContainer: { marginTop: "3rem" },
        headerRow: {
            verticalAlign: "bottom",
        },
        tableRow: {
            boxShadow: theme.mainShadow,
            backgroundColor: "white",
            verticalAlign: "top",
        },
        popup: {
            padding: "12px",
        },
        popupCloneButton: { color: theme.colors.primary.blue500 },
        popupRemoveButton: { color: theme.colors.critical.critical600 },
        optionsIcon: {
            cursor: "pointer",
            minWidth: "0px",
            padding: "0px",
            "&:hover": {
                backgroundColor: "rgb(0,0,0,0)",
            },
        },

        fieldMaxWidth: {
            maxWidth: "30rem",
            wordWrap: "break-word",
        },

        displayNone: {
            "&.sticky": {
                display: "none",
            },
        },
    })
);
