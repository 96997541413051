import { AuthModel } from "api/models/sharedModels/AuthModel";
import { AuthModal } from "components/ReusableComponents/AuthModal/AuthModal";
import { LynxJustificationModal } from "components/LynxComponents/LynxJustificationModal/LynxJustificationModal";
import { StabilityFormModel } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useOktaAuth } from "@okta/okta-react";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { validationHelpers } from "validation/helper-functions";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { useFormikContext } from "formik";
import { routes } from "routes";

interface StabilityFormAuthModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    stabilityFormState: StabilityFormModel;
}

const StabilityFormAuthModal = observer((props: StabilityFormAuthModalProps) => {
    const navigate = useNavigate();
    const formik = useFormikContext<StabilityFormModel>();
    const { oktaAuth } = useOktaAuth();
    const { stabilityFormId } = useParams();
    const { stabilityFormStore, identityStore } = useStore();
    const { isModalOpen, setIsModalOpen, stabilityFormState } = { ...props };

    const [userMessage, setUserMessage] = useState("");
    const [isShowReAuthenticate, setIsShowReAuthenticate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [numberOfRetries, setNumberOfRetries] = useState(2);

    const handleResponse = async (statusCode: number, errors: ApiValidationError[]) => {
        switch (statusCode) {
            case 200:
            case 201:
                setNumberOfRetries(2);
                navigate(routes.stabilityFormDetails.to(stabilityFormStore.formDetails.id));
                break;
            case 401:
                setNumberOfRetries(numberOfRetries - 1);
                setErrorMessage(numberOfRetries + " login attempt remaining, please try again or contact an admin");
                if (numberOfRetries <= 0) {
                    setNumberOfRetries(2);
                    await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}${routes.login}` });
                }
                break;
            case 400:
                stabilityFormStore.setErrors(validationHelpers.generateFormikErrorsFromApi<StabilityFormModel>(errors));
                setIsShowReAuthenticate(false);
                formik.setSubmitting(false);
                break;
            default:
                setErrorMessage("Something went wrong. Please, try again");
                break;
        }
    };

    const submitStabilityForm = async (authModel: AuthModel) => {
        const setForm = stabilityFormState;

        if (stabilityFormId) {
            await stabilityFormStore.editStabilityForm(
                identityStore.currentCustomer.id,
                stabilityFormId,
                setForm,
                authModel,
                userMessage,
                handleResponse
            );
        } else {
            await stabilityFormStore.createStabilityForm(
                identityStore.currentCustomer.id,
                setForm,
                authModel,
                userMessage,
                handleResponse
            );
        }
    };

    return (
        <>
            <LynxJustificationModal
                open={isModalOpen}
                title={"Confirm your actions by providing comments."}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                onConfirm={() => {
                    setIsModalOpen(false);
                    setIsShowReAuthenticate(true);
                }}
                value={userMessage}
                handleValueChange={setUserMessage}
            />
            <AuthModal
                isOpen={isShowReAuthenticate}
                onCancel={() => {
                    setIsShowReAuthenticate(false);
                }}
                onClose={() => {
                    setIsShowReAuthenticate(false);
                }}
                onSubmit={(authModel: AuthModel) => {
                    submitStabilityForm(authModel);
                }}
                errorMessage={errorMessage}
                loading={stabilityFormStore.progressFlags.loadingReAuthentication}
                disabled={stabilityFormStore.progressFlags.loadingReAuthentication}
                onBack={() => {
                    setIsShowReAuthenticate(false);
                    setIsModalOpen(true);
                }}
            />
            &nbsp;
        </>
    );
});

export default StabilityFormAuthModal;
