import { Handle, HandleProps, Position } from "@xyflow/react";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";

interface DummyNodeHandleProps extends Omit<HandleProps, "position"> {}

// Handles are required by ReactFlow to render edges so we create invisible dummy handles
export const DummyNodeHandle = (props: DummyNodeHandleProps) => {
    const classes = batchDistributionDiagramPageStyles();

    return <Handle position={Position.Bottom} isConnectable={false} className={classes.nodeHandle} {...props} />;
};
