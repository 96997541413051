import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTextarea from "components/LynxComponents/LynxTextarea/LynxTextarea";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";
import { stabilityFormStyles } from "components/StabilityForm/StabilityFormStyles";
import { getPermissionKey } from "helpers/permissionHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";

export const FormSection = observer(() => {
    const classes = stabilityFormStyles();
    const { stabilityFormStore } = useStore();
    const navigate = useNavigate();
    const { identityStore, permissionsStore } = useStore();

    const modifyPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.modify,
        identityStore.currentCustomer.id
    );

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    return (
        <SectionCard>
            <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
                <Grid container direction="row" className={withSticky(classes.header)}>
                    <Grid item xs={8}>
                        <LynxTypography variant="h1" className={withSticky(classes.headerText)}>
                            {stabilityFormStore.formDetails.product.name} Stability Form
                        </LynxTypography>
                    </Grid>
                    <Grid item container xs={4} id="buttonsContainer" justifyContent="flex-end">
                        <Grid item>
                            <LynxButton
                                size="medium"
                                disabled={!permissionsStore.hasPermission(modifyPermissionKey)}
                                loading={permissionsStore.permissionLoading(modifyPermissionKey)}
                                onClick={() =>
                                    navigate(routes.stabilityFormsEdit.to(stabilityFormStore.formDetails.id))
                                }
                                leftIcon={<LynxIcon name="edit" />}
                            >
                                Edit
                            </LynxButton>
                        </Grid>
                    </Grid>
                </Grid>
            </HeaderPortal>

            <Grid container spacing={3} className={clsx(classes.documentInfoSection)}>
                <Grid item xs={3}>
                    <LynxTextarea readOnly={true} value={stabilityFormStore.formDetails.number} label="Document No" />
                </Grid>
                <Grid item xs={3}>
                    <LynxTextarea readOnly={true} value={stabilityFormStore.formDetails.version} label="Version" />
                </Grid>
                <Grid item xs={3}>
                    <LynxTextarea
                        readOnly={true}
                        value={stabilityFormStore.formDetails.effectiveDate}
                        label="Effective Date"
                    />
                </Grid>
                <Grid item xs={3}>
                    <LynxTextarea
                        readOnly={true}
                        value={`${stabilityFormStore.formDetails.ownerFirstName} ${stabilityFormStore.formDetails.ownerLastName}`.trim()}
                        label="Form Owner"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
});
