import { apiRoutes } from "api/apiRoutes";
import { GetReportCsvRequest } from "api/models/reporting/reportingApi";
import { torAxios } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";

export class ReportingService {
    getReportCsv = async (request: GetReportCsvRequest) => {
        const url = apiRoutes.reporting.getReportCsv(request.customerId, request.type);

        const requestConfig: AxiosRequestConfig = { params: request, responseType: "blob" };

        return await torAxios.get(url, requestConfig);
    };
}
