import { LynxRedirectLinkProps } from "./LynxRedirectLinkProps";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ContentLoader from "react-content-loader";
import LynxTypography from "../LynxTypography/LynxTypography";
import { lynxRedirectLinkStyles } from "./LynxRedirectLinkStyles";
import clsx from "clsx";

export const LynxRedirectLink = (props: LynxRedirectLinkProps) => {
    const {
        className,
        disabled,
        loading,
        loaderWidth,
        loaderHeight,
        to,
        variant = "body-l",
        color = "blue500",
        typographyComponent,
        ...otherProps
    } = props;

    const [width, height] = [loaderWidth || 100, loaderHeight || 20];
    const classes = lynxRedirectLinkStyles();

    if (disabled) {
        return (
            <LynxTypography variant={variant} color="neutral600" className={className} component={typographyComponent}>
                {props.children}
            </LynxTypography>
        );
    } else if (loading) {
        return (
            <ContentLoader width={width} height={height}>
                <rect x="0" y="0" rx="2" ry="2" width={width} height={height} />
            </ContentLoader>
        );
    } else {
        return (
            <Link component={RouterLink} to={to} {...otherProps} className={clsx(classes.fitContent, className)}>
                <LynxTypography variant={variant} color={color} component={typographyComponent}>
                    {props.children}
                </LynxTypography>
            </Link>
        );
    }
};
