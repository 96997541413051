import { Collapse, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ListEventsRequest } from "api/models/events/eventsApi";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FiltersComponent } from "components/ReusableComponents/FiltersComponent/FiltersComponent";
import FiltersResultEmptyState from "components/ReusableComponents/FiltersComponent/FiltersResultEmptyState";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import EventRowStatus from "components/ThorEvents/utilityComponents/EventRowStatus";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { parseJsonForRequest } from "helpers/jsonParser";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants, noFilterResultMessage, promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { EventType } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { eventsStyles } from "./EventsStyles";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { emptyValue } from "lynxConstants/commonConstants";
import { PaginationArea } from "models/shared/Page";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";

export const EventsView = observer(() => {
    const classes = eventsStyles();

    const navigate = useNavigate();
    const { identityStore, thorEventViewStore, permissionsStore, userStore, customerDataStore, commonStore } =
        useStore();
    const [selectedEvent, setSelectedEvent] = useState("");

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const navigateToCreateEventPage = () => navigate(routes.eventsCreate);

    // add space to last table header to fix key duplication issue
    const tableHeaders: { title: string; width: number }[] = [
        { title: "", width: 4 },
        { title: "Event ID", width: 9 },
        { title: "Assessment Due Date", width: 16 },
        { title: "Delivery ID", width: 15 },
        { title: "Product", width: 20 },
        { title: "Event Status", width: 14 },
        { title: "Assignees", width: 10 },
        { title: " ", width: 12 },
    ];

    // fields that are enable to sorting
    const sortableHeaders = ["Event ID", "Event Status", "Assessment Due Date"];

    const headerToFieldName = (sortingValue: string) => {
        switch (sortingValue) {
            case "Event ID":
                return "DisplayId";
            case "Assessment Due Date":
                return "AssessmentDueDate";
            case "Event Status":
                return "EventStatusId";
            default:
                return "";
        }
    };

    const isHeaderSelected = (header: string) => thorEventViewStore.sortingValue === headerToFieldName(header);

    const handleSortingChanges = (sortingValue: string, direction: "ASC" | "DESC") => {
        const value = headerToFieldName(sortingValue);
        thorEventViewStore.setSortingValue(value);
        thorEventViewStore.setSortingDirection(direction);
    };

    const renderSortIcon = (header: string, isInHeader: boolean = false) => {
        if (!sortableHeaders.includes(header) || isInHeader) {
            return null;
        }

        return (
            <span className={classes.sortIcon}>
                <LynxIcon
                    name={
                        isHeaderSelected(header)
                            ? thorEventViewStore.sortingDirection === "ASC"
                                ? "sortAscending"
                                : "sortDescending"
                            : "sort"
                    }
                />
            </span>
        );
    };

    // TODO: Change logic to not change direction (use default "DESC") when changing selected header.
    // Improve "ThenBy" sorting logic on API (EventListSort)
    const onSortingClick = (header: string, isInHeader: boolean = false) => {
        if (sortableHeaders.includes(header) && !isInHeader) {
            handleSortingChanges(header, thorEventViewStore.sortingDirection === "DESC" ? "ASC" : "DESC");
        }
    };

    // TODO: Add permissions check. This request using with filters and search
    const loadEvents = () => {
        const request: ListEventsRequest = {
            assignedUsers: parseJsonForRequest(thorEventViewStore.assignedUsersSelectedFilters),
            dueDate: parseJsonForRequest(thorEventViewStore.dueDateSelectedFilters),
            priority: parseJsonForRequest(thorEventViewStore.prioritySelectedFilters),
            eventCodes: parseJsonForRequest(thorEventViewStore.eventCodeSelectedFilters),
            evaluationStatus: parseJsonForRequest(thorEventViewStore.evaluationStatusSelectedFilters),
            pageNumber: thorEventViewStore.currentEventPage,
            customerId: identityStore.currentCustomer.id,
            pageSize: thorEventViewStore.eventPageSize,
            direction: thorEventViewStore.sortingDirection,
            orderBy: thorEventViewStore.sortingValue,
            searchValue: thorEventViewStore.searchInputTrimmed,
            searchParameter: thorEventViewStore.searchParameter,
        };

        thorEventViewStore.loadEvents(request);
    };

    const permissionKey = getPermissionKey(actions.customer.tor.events.view, identityStore.currentCustomer.id);
    const eventsViewPermissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    useEffect(() => {
        if (identityStore.isSystemSpace || eventsViewPermissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [identityStore.isSystemSpace, identityStore.startPageAvailable, eventsViewPermissionStatus]);

    const viewDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.events.viewDetails,
        identityStore.currentCustomer.id
    );

    const createEventPermissionKey = getPermissionKey(
        actions.customer.tor.events.create,
        identityStore.currentCustomer.id
    );
    const createEventPermissionStatus = permissionsStore.getPermissionStatus(createEventPermissionKey);

    useEffect(() => {
        if (eventsViewPermissionStatus !== MarvelPermissionStatus.Allow) {
            return;
        }
        setStickyEnabled(false);
        loadEvents();

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [
        eventsViewPermissionStatus,
        thorEventViewStore.currentEventPage,
        thorEventViewStore.eventPageSize,
        thorEventViewStore.sortingDirection,
        thorEventViewStore.sortingValue,
    ]);

    useEffect(() => {
        if (eventsViewPermissionStatus !== MarvelPermissionStatus.Allow) {
            return;
        }

        if (customerDataStore.eventPriorities === null && !customerDataStore.progressFlags.loadingPriorities) {
            customerDataStore.loadEventPriorities({ customerId: identityStore.currentCustomer.id });
        }
        if (customerDataStore.eventCodes.length === 0) {
            customerDataStore.loadEventCodes({ customerId: identityStore.currentCustomer.id });
        }
    }, [eventsViewPermissionStatus]);

    if (eventsViewPermissionStatus !== MarvelPermissionStatus.Allow && !commonStore.showGeneralErrorPage) {
        return <FullScreenLoadingIndicator />;
    }

    const tooltipMessage =
        createEventPermissionStatus !== MarvelPermissionStatus.Allow
            ? promptTooltipMessages.accessDenied
            : promptTooltipMessages.empty;

    const title = `Events (${thorEventViewStore.totalCount})`;

    const renderHeader = () => (
        <TableHead>
            <TableRow className={withSticky(classes.headerRow)}>
                {tableHeaders.map((header) => (
                    <TableCell
                        className={clsx(classes.cellBorderBottom, classes.headerCell)}
                        key={header.title}
                        width={`${header.width}%`}
                    >
                        <LynxTypography
                            onClick={() => onSortingClick(header.title, stickyEnabled)}
                            className={clsx({
                                [classes.sortingHeader]: sortableHeaders.includes(header.title) && !stickyEnabled,
                                [classes.selectedSortingHeader]: isHeaderSelected(header.title),
                            })}
                            color="neutral400"
                            variant="h3"
                        >
                            <Grid container alignItems="center">
                                <Grid item>{header.title}</Grid>
                                <Grid item>{renderSortIcon(header.title, stickyEnabled)}</Grid>
                            </Grid>
                        </LynxTypography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <FiltersComponent
                    title={title}
                    filters={[
                        {
                            options: [
                                { itemDisplayName: "Due Today", itemValue: "DueToday" },
                                { itemDisplayName: "Past Due", itemValue: "PastDue" },
                                { itemDisplayName: "Due Within 7 Days", itemValue: "DueWithin7Days" },
                            ],
                            label: "Due Date Range",
                            selectedOptions: thorEventViewStore.dueDateSelectedFilters,
                            changeFunction: thorEventViewStore.setDueDateFilter,
                            isUsersDropdown: false,
                            isOptionsDownloaded: false,
                        },
                        {
                            options: [
                                { itemDisplayName: "New Event", itemValue: "0" },
                                { itemDisplayName: "Pending Review", itemValue: "1" },
                                { itemDisplayName: "Pending Approval", itemValue: "2" },
                                { itemDisplayName: "Evaluation Complete", itemValue: "3" },
                            ],
                            label: "Event Status",
                            selectedOptions: thorEventViewStore.evaluationStatusSelectedFilters,
                            changeFunction: thorEventViewStore.setEvaluationStatusFilter,
                            isUsersDropdown: false,
                            isOptionsDownloaded: false,
                        },
                        {
                            options:
                                customerDataStore.eventCodes?.map((x) => ({
                                    itemDisplayName: x.name,
                                    itemValue: x.id,
                                })) || [],
                            label: "Event Codes",
                            selectedOptions: thorEventViewStore.eventCodeSelectedFilters,
                            changeFunction: thorEventViewStore.setEventCodeFilter,
                            isUsersDropdown: false,
                            isOptionsDownloaded: false,
                        },
                        {
                            options:
                                customerDataStore.eventPriorities?.map((x) => ({
                                    itemDisplayName: x.name,
                                    itemValue: x.id,
                                })) || [],
                            label: "Priority",
                            selectedOptions: thorEventViewStore.prioritySelectedFilters,
                            changeFunction: thorEventViewStore.setPriorityFilter,
                            isUsersDropdown: false,
                            isOptionsDownloaded: false,
                        },
                        {
                            options: userStore.customerUsers.map((x) => ({
                                itemDisplayName: `${x.firstName} ${x.lastName}`,
                                itemValue: x.id,
                            })),
                            label: "Assigned",
                            selectedOptions: thorEventViewStore.assignedUsersSelectedFilters,
                            changeFunction: thorEventViewStore.setAssignedUsersFilter,
                            isUsersDropdown: true,
                            isOptionsDownloaded: userStore.progressFlags.loadingUsersForDropdown,
                        },
                    ]}
                    request={loadEvents}
                    resetFilters={thorEventViewStore.resetAllFiltersAndSearch}
                    search={{
                        searchValue: thorEventViewStore.searchValue,
                        setSearchValue: thorEventViewStore.setSearchValue,
                        searchParameterProps: {
                            type: "event",
                            parameter: thorEventViewStore.searchParameter,
                            setSearchParameter: thorEventViewStore.setSearchParameter,
                        },
                    }}
                    buttonComponent={
                        <PromptTooltip placement="top" title={tooltipMessage}>
                            <LynxButton
                                size="medium"
                                onClick={navigateToCreateEventPage}
                                disabled={!permissionsStore.hasPermission(createEventPermissionKey)}
                                loading={permissionsStore.permissionLoading(createEventPermissionKey)}
                            >
                                Create an Event
                            </LynxButton>
                        </PromptTooltip>
                    }
                />

                {thorEventViewStore.progressFlags.loadingEvents ||
                thorEventViewStore.progressFlags.loadingPriorities ? (
                    <div>
                        <FullScreenLoadingIndicator />
                    </div>
                ) : (
                    <div className={classes.tableContainer}>
                        {thorEventViewStore.activeEvents.length === 0 ? (
                            <FiltersResultEmptyState message={noFilterResultMessage} />
                        ) : (
                            <>
                                <HeaderPortal
                                    callback={setStickyEnabled}
                                    stickyEnabled={stickyEnabled}
                                    firstLevel={false}
                                >
                                    <Table className={withSticky(classes.table)}>
                                        {stickyEnabled && renderHeader()}
                                    </Table>
                                </HeaderPortal>

                                <Table className={classes.table}>
                                    {!stickyEnabled && renderHeader()}

                                    <TableBody>
                                        {thorEventViewStore.activeEvents.map((event) => (
                                            <React.Fragment key={event.id}>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell
                                                        width={"4%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        {customerDataStore.eventPriorities?.find(
                                                            (x) => x.id === event.priorityId
                                                        ) && (
                                                            <LynxIcon
                                                                name="flag"
                                                                style={{
                                                                    fill: customerDataStore.eventPriorities?.find(
                                                                        (x) => x.id === event.priorityId
                                                                    )?.color,
                                                                }}
                                                            />
                                                        )}
                                                    </TableCell>

                                                    <TableCell
                                                        width={"9%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        <LynxRedirectLink
                                                            to={routes.eventDetails.to(event.id)}
                                                            disabled={
                                                                !permissionsStore.hasPermission(
                                                                    viewDetailsPermissionKey
                                                                )
                                                            }
                                                            loading={permissionsStore.permissionLoading(
                                                                viewDetailsPermissionKey
                                                            )}
                                                        >
                                                            {event.displayId}
                                                        </LynxRedirectLink>
                                                    </TableCell>

                                                    <TableCell
                                                        width={"16%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        <LynxTypography variant="body-l">
                                                            {event.dueDate
                                                                ? dateToFormat(
                                                                      event.dueDate,
                                                                      commonConstants.shortDateFormat,
                                                                      true
                                                                  )
                                                                : commonConstants.emptyValue}
                                                        </LynxTypography>
                                                    </TableCell>

                                                    <TableCell
                                                        width={"15%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        <LynxTypography
                                                            className={clsx(classes.fieldMaxWidth)}
                                                            variant="body-l"
                                                        >
                                                            {event.deliveryNumber ?? emptyValue}
                                                        </LynxTypography>
                                                    </TableCell>

                                                    <TableCell
                                                        width={"20%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        {event.products.map((x) => (
                                                            <LynxTypography
                                                                key={x.productFullName}
                                                                className={clsx(classes.fieldMaxWidth)}
                                                                variant="body-l"
                                                            >
                                                                {x.productFullName}
                                                            </LynxTypography>
                                                        ))}
                                                    </TableCell>

                                                    <TableCell
                                                        width={"14%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        <LynxTypography>
                                                            <EventRowStatus status={event.status} />
                                                        </LynxTypography>
                                                    </TableCell>

                                                    <TableCell
                                                        width={"10%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                    >
                                                        <LynxTypography variant="body-l">
                                                            <Grid container>
                                                                <Grid item>
                                                                    {event.reviewerUserId && (
                                                                        <LynxAvatarWithTooltip
                                                                            email={event.reviewerUserId}
                                                                            firstName={event.reviewerFirstName}
                                                                            lastName={event.reviewerLastName}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                                <Grid item>
                                                                    {event.qaUserId && (
                                                                        <LynxAvatarWithTooltip
                                                                            email={event.qaUserId}
                                                                            firstName={event.qaFirstName}
                                                                            lastName={event.qaLastName}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            {!event.reviewerUserId &&
                                                                !event.qaUserId &&
                                                                commonConstants.emptyValue}
                                                        </LynxTypography>
                                                    </TableCell>

                                                    <TableCell
                                                        width={"12%"}
                                                        className={clsx({
                                                            [classes.cellBorderBottom]: selectedEvent === event.id,
                                                        })}
                                                        onClick={() => {
                                                            const eventId = selectedEvent === event.id ? "" : event.id;
                                                            setSelectedEvent(eventId);
                                                        }}
                                                    >
                                                        <LynxTypography
                                                            variant="body-sbold"
                                                            className={classes.expandableToggleText}
                                                        >
                                                            {selectedEvent === event.id ? "Collapse" : "Expand"} info
                                                            <LynxIcon
                                                                name="angleSmallDown"
                                                                className={clsx(classes.detailsSectionIcon, {
                                                                    [classes.rotatedSortIcon]:
                                                                        selectedEvent === event.id,
                                                                })}
                                                            />
                                                        </LynxTypography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        className={clsx(classes.cellBorderBottom, classes.paddingNone)}
                                                    >
                                                        <Collapse in={selectedEvent === event.id}>
                                                            <Paper elevation={0} className={classes.detailsDropdown}>
                                                                <Grid
                                                                    container
                                                                    spacing={4}
                                                                    className={classes.wordBreak}
                                                                >
                                                                    <Grid item xs={2}>
                                                                        <LynxTypography
                                                                            variant="body-s"
                                                                            color="neutral400"
                                                                            className={
                                                                                classes.detailsSectionHeadersMargin
                                                                            }
                                                                        >
                                                                            Event Creation Date
                                                                        </LynxTypography>
                                                                        <LynxTypography>
                                                                            {convertDateToSelectedTimezone(
                                                                                event.createdAt,
                                                                                event.timezone
                                                                            ).format(
                                                                                commonConstants.fullDateTimeFormat
                                                                            )}
                                                                        </LynxTypography>
                                                                    </Grid>

                                                                    {event.type === EventType.Transportation && (
                                                                        <>
                                                                            <Grid item xs={2}>
                                                                                <LynxTypography
                                                                                    variant="body-s"
                                                                                    color="neutral400"
                                                                                    className={
                                                                                        classes.detailsSectionHeadersMargin
                                                                                    }
                                                                                >
                                                                                    Origin Site
                                                                                </LynxTypography>
                                                                                <LynxTypography>
                                                                                    {event.originName ??
                                                                                        commonConstants.emptyValue}
                                                                                </LynxTypography>
                                                                            </Grid>

                                                                            <Grid item xs={2}>
                                                                                <LynxTypography
                                                                                    variant="body-s"
                                                                                    color="neutral400"
                                                                                    className={
                                                                                        classes.detailsSectionHeadersMargin
                                                                                    }
                                                                                >
                                                                                    Destination Site
                                                                                </LynxTypography>
                                                                                <LynxTypography>
                                                                                    {event.destinationName ??
                                                                                        commonConstants.emptyValue}
                                                                                </LynxTypography>
                                                                            </Grid>
                                                                        </>
                                                                    )}

                                                                    <Grid
                                                                        item
                                                                        xs={
                                                                            event.type === EventType.Transportation
                                                                                ? 6
                                                                                : 10
                                                                        }
                                                                    >
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell
                                                                                        width="50%"
                                                                                        className={clsx(
                                                                                            classes.cellBorderBottom,
                                                                                            classes.paddingBottomNone,
                                                                                            classes.paddingTopNone
                                                                                        )}
                                                                                    >
                                                                                        <LynxTypography
                                                                                            variant="body-s"
                                                                                            color="neutral400"
                                                                                            className={
                                                                                                classes.detailsSectionHeadersMargin
                                                                                            }
                                                                                        >
                                                                                            Product Name
                                                                                        </LynxTypography>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        width="50%"
                                                                                        className={clsx(
                                                                                            classes.cellBorderBottom,
                                                                                            classes.paddingBottomNone,
                                                                                            classes.paddingTopNone
                                                                                        )}
                                                                                    >
                                                                                        <LynxTypography
                                                                                            variant="body-s"
                                                                                            color="neutral400"
                                                                                            className={
                                                                                                classes.detailsSectionHeadersMargin
                                                                                            }
                                                                                        >
                                                                                            Batch ID(s)
                                                                                        </LynxTypography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {event.products.map((x) => (
                                                                                    <TableRow
                                                                                        key={x.productFullName}
                                                                                        className={
                                                                                            classes.detailsSectionTable
                                                                                        }
                                                                                    >
                                                                                        <TableCell width="50%">
                                                                                            <LynxTypography>
                                                                                                {x.productFullName}
                                                                                            </LynxTypography>
                                                                                        </TableCell>
                                                                                        <TableCell width="50%">
                                                                                            <LynxTypography>
                                                                                                {x.batches.join(", ")}
                                                                                            </LynxTypography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>

                                {thorEventViewStore.activeEvents.length > 0 && (
                                    <ThorPagination
                                        page={thorEventViewStore.currentEventPage}
                                        pages={thorEventViewStore.totalEventPages}
                                        onPageChange={thorEventViewStore.moveToEventPage}
                                        isLastPage={thorEventViewStore.isLastEventPage}
                                        setPage={thorEventViewStore.setEventPage}
                                        area={PaginationArea.Events}
                                        setPageSize={thorEventViewStore.setEventsPageSize}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
