import "@xyflow/react/dist/style.css";
import { observer } from "mobx-react";
import { Controls, ReactFlow, useEdgesState, useNodesInitialized, useNodesState, useReactFlow } from "@xyflow/react";
import { useEffect, useState } from "react";
import { LocationNode } from "./LocationNode";
import { UnknownLocationNode } from "./UnknownLocationNode";
import { DeliveryNode } from "./DeliveryNode";
import { DeliveryEdge } from "./DeliveryEdge";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { getInitialNodesForDiagram, layoutNodes } from "./batchDistributionDiagramHelpers";
import { useStore } from "store/StoreConfigs";
import { LynxEdge, LynxEdgeType, LynxNode, LynxNodeType } from "./BatchDistributionDiagramTypes";
import { CenteredLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/CenteredLoadingIndicator";
import { GroupedDeliveriesNode } from "./GroupedDeliveriesNode";

const nodeTypes: { [key in LynxNodeType]: any } = {
    deliveryNode: DeliveryNode,
    locationNode: LocationNode,
    unknownLocationNode: UnknownLocationNode,
    groupedDeliveriesNode: GroupedDeliveriesNode,
};

const edgeTypes: { [key in LynxEdgeType]: any } = {
    deliveryEdge: DeliveryEdge,
};

export const BatchDistributionDiagram = observer(() => {
    const classes = batchDistributionDiagramPageStyles();
    const { batchDistributionDiagramStore } = useStore();
    const initialNodes = getInitialNodesForDiagram(
        batchDistributionDiagramStore.diagramData,
        batchDistributionDiagramStore.isRelevantEventsHighlighted
    );

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState<LynxEdge>([]);

    const [layoutComputed, setLayoutComputed] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const nodesInitialized = useNodesInitialized();
    const { fitView } = useReactFlow();

    useEffect(() => {
        const updatedNodes = nodes.map((node) => ({
            ...node,
            data: {
                ...node.data,
                isRelevantEventsHighlighted: batchDistributionDiagramStore.isRelevantEventsHighlighted,
            },
        }));

        setNodes(updatedNodes as LynxNode[]);
    }, [batchDistributionDiagramStore.isRelevantEventsHighlighted]);

    useEffect(() => {
        (async () => {
            if (nodesInitialized && !layoutComputed) {
                const result = await layoutNodes(nodes);
                setNodes(result.nodesAfterLayout);
                setEdges(result.edgesAfterLayout);
                setLayoutComputed(true);
            }
        })();
    }, [setNodes, setEdges, fitView, nodesInitialized, layoutComputed]);

    useEffect(() => {
        if (nodesInitialized && layoutComputed) {
            fitView();
            setShowLoading(false);
        }
    }, [nodesInitialized, layoutComputed]);

    return (
        <div className={classes.diagramContainer}>
            <ReactFlow
                maxZoom={10}
                minZoom={0.1}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                proOptions={{ hideAttribution: true }}
            >
                <Controls showInteractive={false} showFitView={true} position="bottom-right" />
            </ReactFlow>

            {showLoading && nodes.length > 0 && (
                <div className={classes.loadingIndicatorContainer}>
                    <CenteredLoadingIndicator />
                </div>
            )}
        </div>
    );
});
