import { EdgeProps, getStraightPath, useInternalNode } from "@xyflow/react";
import { getEdgeParams } from "./batchDistributionDiagramHelpers";
import { LynxDeliveryEdge } from "./BatchDistributionDiagramTypes";

const getCurvedPath = (sx: number, sy: number, tx: number, ty: number) => {
    const mpx = (sx + tx) * 0.5;
    const mpy = (sy + ty) * 0.5;
    const theta = Math.atan2(ty - sy, tx - sx) - Math.PI / 2;
    const offset = 25;

    const cx = mpx + offset * Math.cos(theta);
    const cy = mpy + offset * Math.sin(theta);
    const path = "M" + sx + " " + sy + " Q " + cx + " " + cy + " " + tx + " " + ty;
    return path;
};

export const DeliveryEdge = (props: EdgeProps<LynxDeliveryEdge>) => {
    const { id, source, target, data, markerEnd, style } = props;
    const sourceNode = useInternalNode(source);
    const targetNode = useInternalNode(target);

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sourceX, sourceY, targetX, targetY } = getEdgeParams(sourceNode, targetNode);

    const getEdgePath = () => {
        return data?.circular
            ? getCurvedPath(sourceX, sourceY, targetX, targetY)
            : getStraightPath({ sourceX, sourceY, targetX, targetY })[0];
    };

    return (
        <path
            id={id}
            className="react-flow__edge-path"
            d={getEdgePath()}
            markerEnd={data?.direction === "outgoing" ? markerEnd : undefined}
            style={style}
        />
    );
};
