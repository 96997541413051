import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const assessmentDetailsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
        },

        verticalAlignTop: {
            verticalAlign: "top",
        },

        verticalAlignBottom: {
            verticalAlign: "bottom",
        },

        generalInfoTable: {
            width: "60%",
        },

        generalInfoHeaderCell: {
            borderBottom: "none",
            padding: "0rem 0.75rem 0.25rem 0.75rem",

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingRight: 0,
            },
        },

        generalInfoRowCell: {
            borderBottom: "none",
            padding: "0.5rem 0.75rem",

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingTop: 0,
                paddingRight: 0,
            },
        },

        productInfoHeader: {
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
        },

        productInfoBody: {
            backgroundColor: theme.colors.primary.blue75,
        },

        productName: {
            paddingLeft: "0.5rem",
        },

        assessmentDecisionSection: {
            gap: "1.5rem",
        },

        automatedAssessmentCard: {
            padding: "0.5rem 1rem 0.5rem 0.5rem",
        },

        temperatureTableHeaderCell: {
            padding: "0rem 0.375rem 0.5rem 0.375rem",

            "&:first-child": {
                paddingLeft: "0.5rem",
            },

            "&:last-child": {
                paddingRight: "0.5rem",
            },
        },

        temperatureTableRowCell: {
            padding: "0.5rem 0.375rem",

            "&:last-child": {
                paddingRight: "0.5rem",
            },

            "&:first-child": {
                paddingLeft: "0.5rem",
            },
        },

        temperatureTableRows: {
            "&:first-child, &:last-child": {
                backgroundColor: theme.colors.primary.blue75,
            },
        },

        temperatureRangeCell: {
            display: "flex",
            gap: "0.25rem",
        },

        temperatureRangeCellIconContainer: {
            width: "1.5rem",
            height: "1.5rem",

            "& > svg": {
                width: "inherit",
                height: "inherit",
                fill: theme.colors.critical.critical600,
            },
        },

        historyTableRow: {
            "&:nth-child(odd)": {
                backgroundColor: theme.colors.primary.blue75,
            },
        },

        batchHistoryTempCell: {
            minWidth: "9rem",
        },

        greenIcon: {
            fill: theme.colors.success.success600,
        },

        yellowIcon: {
            fill: theme.colors.warning.warning600,
        },

        redIcon: {
            fill: theme.colors.critical.critical600,
        },

        greyIcon: {
            fill: theme.colors.neutrals.neutral300,
            width: "1rem",
            height: "1rem",
        },

        automatedAssessmentCardsContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },

        assessmentDecisionLeftSide: {
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
        },

        assessmentDecisionButtonsContainer: {
            display: "flex",
            gap: "1rem",
        },
        icon: {
            fill: theme.colors.neutrals.neutral400,
            marginLeft: "0.5rem",
            width: "0.875rem",
            height: "0.875rem",
        },
        transportationTooltip: {
            backgroundColor: theme.colors.neutrals.neutral600,
            color: theme.palette.common.white,
            paddingLeft: "1rem",
            minWidth: "20rem",
        },
        transportationTooltipArrow: {
            "&:before": {
                backgroundColor: theme.colors.neutrals.neutral600,
            },
        },
        tooltipSection: {
            display: "inline-block",
        },
        infoCard: {
            display: "flex",
            gap: "0.5rem",
            padding: "0.5rem 1rem 0.5rem 0.5rem",
            borderRadius: theme.borderRadius,
            border: `1px solid ${theme.colors.primary.blue100}`,

            "& > svg": {
                flexShrink: 0,
            },
        },

        batchHistoryCardContainer: {
            backgroundColor: theme.colors.primary.blue75,
            border: `1px solid ${theme.colors.primary.blue100}`,
            borderRadius: theme.borderRadius,
            padding: "1rem",
            marginBottom: "0.5rem",
        },

        batchHistoryDataCell: {
            borderBottom: "none",
            paddingTop: "0.25rem",
            paddingLeft: "0rem",
        },

        batchHistoryHeaderCell: {
            borderBottom: "none",
            paddingTop: "1.5rem",
            paddingBottom: "0.25rem",
            paddingLeft: "0rem",
        },

        batchHistoryEventCreationDateMargin: {
            marginRight: "1.5rem",
        },

        batchHistoryloadingIndicator: {
            width: "20rem",
            margin: "auto",
        },

        batchHistoryTabsContainer: {
            display: "inline-block",
        },

        batchHistoryNavigationContainer: {
            display: "flex",
            gap: "1rem",
        },

        closedBatchInfoBadge: {
            paddingBottom: "1.5rem",
        },

        batchDistributionDiagramButton: {
            padding: "0.75rem 1rem",
        },
    })
);
