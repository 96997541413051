import { NodeProps } from "@xyflow/react";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxLocationNode } from "./BatchDistributionDiagramTypes";
import { DummyNodeHandle } from "./DummyNodeHandle";
import { EventCard } from "./EventCard";
import clsx from "clsx";

export const LocationNode = (props: NodeProps<LynxLocationNode>) => {
    const classes = batchDistributionDiagramPageStyles();

    const areAllEventsNotRelevant = props.data.events.length > 0 && props.data.events.every((x) => !x.isRelevant);

    return (
        <>
            <div
                className={clsx(classes.locationNode, {
                    [classes.greyedOutCard]: props.data.isRelevantEventsHighlighted && areAllEventsNotRelevant,
                    [classes.selectedEventNode]: props.data.events.some((x) => x.id === props.data.currentEventId),
                })}
            >
                <LynxTypography variant="body-sbold-s" color="white">
                    {props.data.code}, {props.data.name}
                </LynxTypography>
                {props.data.events.length !== 0 &&
                    props.data.events.map((event) => (
                        <EventCard
                            currentEventId={props.data.currentEventId}
                            event={event}
                            key={event.id}
                            isRelevantEventsHighlighted={props.data.isRelevantEventsHighlighted}
                        />
                    ))}
            </div>
            <DummyNodeHandle type="source" />
            <DummyNodeHandle type="target" />
        </>
    );
};
