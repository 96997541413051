import { Grid } from "@material-ui/core";
import LynxDropdown from "components/LynxComponents/LynxDropdown/LynxDropdown";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import FilterBubbles from "../FilterBubbles/FilterBubbles";
import { FiltersComponentProps } from "./FiltersComponentProps";
import { filtersComponentStyles } from "./FiltersComponentStyles";
import { getSearchPlaceholder, LynxSearch, LynxSearchParametersSelect } from "components/LynxComponents/LynxSearch";
import { HeaderPortal } from "../HeaderPortal";
import clsx from "clsx";
import { useState } from "react";

export const FiltersComponent = (props: FiltersComponentProps) => {
    const { title, filters, request, resetFilters, search, buttonComponent } = props;
    const classes = filtersComponentStyles();

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const cleanAllFilters = () => {
        resetFilters();
        request();
    };

    return (
        <>
            <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
                <Grid container direction="row" className={withSticky(classes.filterWrap)}>
                    <Grid item container>
                        <Grid item container alignItems="center">
                            <Grid item alignItems="center">
                                <LynxTypography variant={stickyEnabled ? "h2" : "h1"}>{title}</LynxTypography>
                            </Grid>
                            <Grid item className={withSticky(classes.displayNone)}>
                                <LynxSearchParametersSelect {...search.searchParameterProps} />
                            </Grid>
                            <Grid item className={withSticky(classes.displayNone)}>
                                <LynxSearch
                                    searchValue={search.searchValue}
                                    setSearchValue={search.setSearchValue}
                                    request={request}
                                    placeholder={getSearchPlaceholder(
                                        search.searchParameterProps.type,
                                        search.searchParameterProps.parameter
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            className={withSticky(classes.filtersMargin)}
                        >
                            <LynxTypography color="neutral400">Filter By:</LynxTypography>
                            {filters.map((filter) => (
                                <Grid key={filter.label}>
                                    <LynxDropdown
                                        isUsersDropdown={filter.isUsersDropdown}
                                        options={filter.options}
                                        label={filter.label}
                                        loading={filter.isOptionsDownloaded}
                                        value={filter.selectedOptions.length === 0 ? [""] : filter.selectedOptions}
                                        onChange={(e) => {
                                            filter.changeFunction(e.target.value as string[]);
                                            request();
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <FilterBubbles
                                    resetFiltersFunc={cleanAllFilters}
                                    items={filters.map((filter) => ({
                                        selectedValues: filter.selectedOptions,
                                        filterName: filter.label,
                                        changeFunction: (value: string[]) => {
                                            filter.changeFunction(value);
                                            request();
                                        },
                                    }))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={2} justifyContent="flex-end">
                        <Grid item>{buttonComponent}</Grid>
                    </Grid>
                </Grid>
            </HeaderPortal>
        </>
    );
};
