import { ListCustomerUsersRequest } from "api/models/userManagement/userManagementApi";
import { Form, Formik } from "formik";
import { getPermissionKey } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { StabilityFormModel } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useStore } from "store/StoreConfigs";
import { ScrollToFirstFormFieldError } from "validation/HelperComponents/ScrollToFirstFormFieldError";
import SetApiValidationErrors from "validation/HelperComponents/SetApiValidationsErrors";
import { stabilityFormValidationSchema } from "validation/ValidationSchemas/stabilityFormValidationSchema";
import ProductFieldsArea from "../FormFieldsAreas/ProductFieldsArea";
import { StabilityFormFieldsArea } from "../FormFieldsAreas/StabilityFormFieldsArea";
import TemperatureRangesArea from "../FormFieldsAreas/TemperatureRangesArea";
import StabilityFormAuthModal from "./StabilityFormAuthModal";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { Helmet } from "react-helmet";
import PreventLeavePageUnsavedData from "components/ReusableForms/helper-components/PreventLeavePageUnsavedData";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { routes } from "routes";
import { LynxBackButton } from "components/ReusableComponents/LynxBackButton/LynxBackButton";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";

export const StabilityFormDetails = observer(() => {
    const { stabilityFormId } = useParams();
    const classes = stabilityFormStyles();
    const [stabilityFormState, setStabilityFormState] = useState<StabilityFormModel>({} as StabilityFormModel);

    const { stabilityFormStore, identityStore, userStore, permissionsStore, customerDataStore, commonStore } =
        useStore();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const key = getPermissionKey(actions.customer.tor.stabilityForms.modify, identityStore.currentCustomer.id);
    const modifyPermissionStatus = permissionsStore.getPermissionStatus(key);

    const waitingForPermissionCheck =
        modifyPermissionStatus === undefined || modifyPermissionStatus === MarvelPermissionStatus.Loading;

    const accessDenied = identityStore.isSystemSpace || modifyPermissionStatus === MarvelPermissionStatus.Deny;
    const isValidGuid = isGuidValid(stabilityFormId);

    useEffect(() => {
        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [accessDenied]);

    useEffect(() => {
        if (!!stabilityFormId && !isValidGuid) {
            return navigate(routes.pageNotFound);
        }
    }, [stabilityFormId]);

    const getRegions = () => {
        if (customerDataStore.regions.length === 0) {
            customerDataStore.loadRegions({ customerId: identityStore.currentCustomer.id });
        }
    };

    const getProductSupportInfo = () => {
        if (
            customerDataStore.productSupportInfo.categories.length <= 0 ||
            customerDataStore.productSupportInfo.doseForms.length <= 0 ||
            customerDataStore.productSupportInfo.presentations.length <= 0 ||
            customerDataStore.productSupportInfo.storageTypes.length <= 0 ||
            customerDataStore.productSupportInfo.unitOfMeasures.length <= 0
        ) {
            customerDataStore.loadCustomerProductSupportInfo({ customerId: identityStore.currentCustomer.id });
        }
    };

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck || !stabilityFormId) {
            return;
        }

        stabilityFormStore.getStabilityFormForEditing(identityStore.currentCustomer.id, stabilityFormId);

        return () => {
            stabilityFormStore.resetStabilityFormData();
            commonStore.setShowGeneralErrorPageToFalse();
        };
    }, [modifyPermissionStatus]);

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck) {
            return;
        }

        getProductSupportInfo();

        getRegions();
    }, [modifyPermissionStatus]);

    useEffect(() => {
        const request: ListCustomerUsersRequest = {
            pageNumber: 1,
            pageSize: 5,
            customerId: identityStore.currentCustomer.id,
        };
        userStore.loadCustomerUsers(request);
    }, []);

    const stabilityFormInitialValue: StabilityFormModel = {
        ...stabilityFormStore.formDetails,
        product: {
            ...stabilityFormStore.formDetails.product,
            representations: stabilityFormStore.formDetails.product.representations.map((x) => ({
                ...x,
                key: "",
            })),
        },
        temperatureRanges: stabilityFormStore.formDetails.temperatureRanges.map((x) => ({
            ...x,
            key: "",
            rangeRegions: x.rangeRegions.map((y) => ({
                ...y,
                key: "",
                flows: y.flows.map((q) => ({
                    ...q,
                    key: "",
                    isReferenced: q.isReferenced,
                })),
            })),
        })),
    };

    const setStabilityForm = async (form: StabilityFormModel) => {
        setStabilityFormState(form);
        setIsModalOpen(true);
    };

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>
                        {stabilityFormId
                            ? `Edit ${stabilityFormStore.formDetails.product.name} Stability Form`
                            : "Create Stability Form"}
                    </title>
                </Helmet>
                {stabilityFormStore.progressFlags.loadingStabilityForm ||
                customerDataStore.progressFlags.loadRegions ||
                customerDataStore.progressFlags.loadProductSupportInfo ? (
                    <div>
                        <FullScreenLoadingIndicator />
                    </div>
                ) : (
                    <>
                        <LynxBackButton path={routes.stabilityForms} title={"Stability Forms List"} />
                        <Formik
                            initialValues={stabilityFormInitialValue}
                            validationSchema={stabilityFormValidationSchema()}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                setStabilityForm(values);
                            }}
                        >
                            <Form id="stabilityForm">
                                <PreventLeavePageUnsavedData />

                                <StabilityFormFieldsArea />

                                <ProductFieldsArea />

                                <TemperatureRangesArea />

                                <StabilityFormAuthModal
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    stabilityFormState={stabilityFormState}
                                />

                                <ScrollToFirstFormFieldError<StabilityFormModel> />

                                <SetApiValidationErrors storeErrors={stabilityFormStore.formErrors} />
                            </Form>
                        </Formik>
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
