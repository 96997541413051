// TODO: refactor user management routing
export const routes = {
    // common
    root: "/",
    pageNotFound: "/pageNotFound",

    // user authentication and account management
    login: "/login",
    termsAndConditions: "/termsAndConditions",
    forgotPassword: "/forgot-password",
    recoveryEmailed: "/recovery-emailed",
    passwordSuccessfullyReset: "/password-successfully-reset",
    resetPassword: "/reset-password",
    recoveryTokenInvalid: "/recovery-token-invalid",

    // events
    events: "/events",
    eventDetails: { path: "/events/:eventId", to: (eventId: string) => `/events/${eventId}` },
    eventsCreate: "/events/create",
    eventsEdit: { path: "/events/:eventId/edit", to: (eventId: string) => `/events/${eventId}/edit` },
    eventsAudit: { path: "/events/:eventId/audit", to: (eventId: string) => `/events/${eventId}/audit` },
    batchDistributionDiagram: {
        path: "/events/:eventId/assessments/:assessmentId/batchDistributionDiagram",
        to: (eventId: string, assessmentId: string) =>
            `/events/${eventId}/assessments/${assessmentId}/batchDistributionDiagram`,
    },

    // stability forms
    stabilityForms: "/stabilityForms",
    stabilityFormDetails: {
        path: "/stabilityForms/:stabilityFormId",
        to: (stabilityFormId: string) => `/stabilityForms/${stabilityFormId}`,
    },
    stabilityFormsCreate: "/stabilityForms/create",
    stabilityFormsEdit: {
        path: "/stabilityForms/:stabilityFormId/edit",
        to: (stabilityFormId: string) => `/stabilityForms/${stabilityFormId}/edit`,
    },
    stabilityFormsAudit: {
        path: `/stabilityForms/:stabilityFormId/audit`,
        to: (stabilityFormId: string) => `/stabilityForms/${stabilityFormId}/audit`,
    },

    // reports
    reporting: "/reporting",

    // user management
    userManagement: "/userManagement",
} as const;
