import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import Strong from "components/LynxComponents/LynxTypography/Strong";
import { IconFrame } from "components/PopupNotification/IconFrame";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { loginStyles } from "../LoginStyles";

const RecoveryEmailSent = observer(() => {
    const classes = loginStyles();
    const { userStore } = useStore();
    const navigate = useNavigate();

    // this page can only be visited programatically
    useEffect(() => {
        if (!userStore.forgotPasswordNavigationFlags.emailSentPageVisited) {
            navigate(routes.login, { replace: true });
        }

        return () => {
            userStore.setForgotPasswordNavigationFlag("emailSentPageVisited", false);
            userStore.setResetPasswordInitializedBy({ userId: "", firstName: "", lastName: "" });
        };
    }, []);

    return (
        <>
            <LynxTypography variant="h2" className={clsx(classes.title, classes.marginTopVeryLarge)}>
                Reset Password
            </LynxTypography>

            <IconFrame type={NotificationType.SUCCESS}>
                <LynxIcon name="check" />
            </IconFrame>

            <LynxTypography className={clsx(classes.marginTopMiddle, classes.wordBreakAll)}>
                Email sent to <Strong>{userStore.resetPasswordInitializedBy.userId}</Strong>
            </LynxTypography>

            <LynxTypography>Check your email and open the link we sent to continue</LynxTypography>
        </>
    );
});

export default RecoveryEmailSent;
