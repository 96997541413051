import { RootEntityType } from "api/models/audit/auditApi";
import { AuditSection } from "components/ReusableComponents/AuditSection/AuditSection";
import { getPermissionKey } from "helpers/permissionHelpers";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";

export const EventAuditPage = observer(() => {
    const { eventId = "" } = useParams();
    const isEventIdValid = isGuidValid(eventId);
    const { identityStore, permissionsStore } = useStore();
    const navigate = useNavigate();

    const permissionKey = getPermissionKey(actions.customer.tor.events.viewDetails, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);
    const permissionLoading = permissionStatus === undefined || permissionStatus === MarvelPermissionStatus.Loading;
    const accessDenied = identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny;

    const conditionsToLoadEventMet = isEventIdValid && !accessDenied && !permissionLoading;

    useEffect(() => {
        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [accessDenied]);

    useEffect(() => {
        if (!isEventIdValid) {
            return navigate(routes.pageNotFound);
        }
    }, [isEventIdValid]);

    return (
        <AuditSection
            entityId={eventId}
            entityType={RootEntityType.Event}
            backButtonTitle="Event Details"
            backRedirectRoute={routes.eventDetails.to(eventId)}
            titlePrefix="Event"
            fetchDataIsAllowed={conditionsToLoadEventMet}
            loading={!conditionsToLoadEventMet}
        />
    );
});
